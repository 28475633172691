import "./assets/styles/main.scss";
import Footer from "./components/Footer";
import Landing from "./components/Landing";

function App() {
  return (
    <div className="App">
      <Landing />
      <Footer />
    </div>
  );
}

export default App;
