const Landing = () => {
  return (
    <div className="landing-container">
      <header className="landing-header">
        <div className="landing-header-title">
          <h1 className="adam-light">ADAM</h1>
          <h1 className="adam-bold adam-glitch-effect">N0M4D</h1>
        </div>
        <div className="landing-header-footer">
          <div className="social-icons">
            <a
              href="https://www.instagram.com/adamn0m4d/"
              target={"_blank"}
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.x.com/adamn0m4d"
              target={"_blank"}
              rel="noreferrer"
            >
              <i className="fa-brands fa-x-twitter"></i>
            </a>
          </div>
        </div>
      </header>
      <div className="landing-sidebar">
        <h3>Soon...</h3>
      </div>
    </div>
  );
};

export default Landing;
