const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; {currentYear} ADAM N0M4D | All rights reserved. </li>
      </ul>
      <span className="jaes-made-it">
        Developed by{" "}
        <a
          href="https://www.jaesmadeit.com/"
          target={"_blank"}
          rel="noreferrer"
        >
          JAES Made It
        </a>
      </span>
    </footer>
  );
};

export default Footer;
